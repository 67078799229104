// src/App.js
import React, { useState } from 'react';
import LanguageSelector from './components/LanguageSelector';
import './App.css';

const content = {
  en: {
    title: "What is Monero?",
    description: "Monero is a privacy-focused cryptocurrency that offers anonymous transactions. It uses advanced cryptography to ensure transactions cannot be traced back to users.",
    howToBuyTitle: "How to Buy Monero?",
    howToBuyDescription: (
      <>
        You can buy Monero on various cryptocurrency exchanges such as 
        <a href="https://simpleswap.io/customer-account/signup?referral=r8gfaBJpm8/" target="_blank" rel="noopener noreferrer"> SimpleSwap</a>, 
        or directly through peer-to-peer platforms.
      </>
    ),
    howToMineTitle: "How to Mine Monero?",
    howToMineDescription: (
      <>
        Monero can be mined using CPUs and GPUs. It's designed to be ASIC-resistant, meaning you can mine it using consumer-grade hardware.
      </>
    ),
  },
  es: {
    title: "¿Qué es Monero?",
    description: "Monero es una criptomoneda centrada en la privacidad que ofrece transacciones anónimas. Utiliza criptografía avanzada para asegurar que las transacciones no puedan rastrearse hasta los usuarios.",
    howToBuyTitle: "¿Cómo comprar Monero?",
    howToBuyDescription: (
      <>
        Puedes comprar Monero en varios intercambios de criptomonedas como 
        <a href="https://simpleswap.io/customer-account/signup?referral=r8gfaBJpm8/" target="_blank" rel="noopener noreferrer"> SimpleSwap</a>, 
        o directamente a través de plataformas peer-to-peer.
      </>
    ),
    howToMineTitle: "¿Cómo minar Monero?",
    howToMineDescription: (
      <>
        Monero se puede minar utilizando CPUs y GPUs. Está diseñado para ser resistente a ASIC, lo que significa que puedes minarlo usando hardware de consumo.
      </>
    ),
  },
  ca: {
    title: "Què és Monero?",
    description: "Monero és una criptomoneda centrada en la privacitat que ofereix transaccions anònimes. Utilitza criptografia avançada per assegurar que les transaccions no puguin rastrejar-se fins als usuaris.",
    howToBuyTitle: "Com comprar Monero?",
    howToBuyDescription: (
      <>
        Pots comprar Monero en diversos intercanvis de criptomonedes com 
        <a href="https://simpleswap.io/customer-account/signup?referral=r8gfaBJpm8/" target="_blank" rel="noopener noreferrer"> SimpleSwap</a>, 
        o directament a través de plataformes peer-to-peer.
      </>
    ),
    howToMineTitle: "Com minar Monero?",
    howToMineDescription: (
      <>
        Monero es pot minar utilitzant CPUs i GPUs. Està dissenyat per ser resistent a ASIC, el que significa que el pots minar amb maquinari de consum.
      </>
    ),
  },
};

function App() {
  const [language, setLanguage] = useState('ca');

  const handleChangeLanguage = (event) => {
    setLanguage(event.target.value);
  };

  return (
    <div className="App">
      <LanguageSelector selectedLanguage={language} onChangeLanguage={handleChangeLanguage} />
      <div className="content-card">
        <h1>{content[language].title}</h1>
        <p>{content[language].description}</p>
        
        <h2>{content[language].howToBuyTitle}</h2>
        <p>{content[language].howToBuyDescription}</p>
        
        <h2>{content[language].howToMineTitle}</h2>
        <p>{content[language].howToMineDescription}</p>
      </div>
    </div>
  );
}

export default App;
