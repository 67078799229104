// src/components/LanguageSelector.js
import React from 'react';

const languages = [
  { code: 'en', label: 'English', icon: 'fas fa-globe-americas' },
  { code: 'es', label: 'Español', icon: 'fas fa-globe-europe' },
  { code: 'ca', label: 'Català', icon: 'fas fa-globe' },
];

const LanguageSelector = ({ selectedLanguage, onChangeLanguage }) => {
  return (
    <div className="language-selector">
      {languages.map((language) => (
        <span
          key={language.code}
          className={`language-option ${selectedLanguage === language.code ? 'active' : ''}`}
          onClick={() => onChangeLanguage({ target: { value: language.code } })}
        >
          <i className={language.icon}></i> {language.label}
        </span>
      ))}
    </div>
  );
};

export default LanguageSelector;
